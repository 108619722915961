import React, { useEffect, useState } from "react";
//import logo from "./logo.svg";
import "./styles/App.css";
import MainPage from "./pages/MainPage/MainPage";
import Navbar from "./pages/Navbar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminPage from "./pages/AdminPage/AdminPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import TeamPage from "./pages/TeamPage/TeamPage";
import BolidPage from "./pages/BolidPage/BolidPage";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import KontaktPage from "./pages/KontaktPage/KontaktPage";
import ZawodyPage from "./pages/ZawodyPage/ZawodyPage";
import TestPage from "./pages/TestPage/TestPage";
function App() {
   const [A, B] = useState("polish-language");
   let languageHandler = {
   chosenLanguage: A,
   setChosenLanguage: B,
   }
   let isEnglish = languageHandler.chosenLanguage === "english-language";
  useEffect(() => {
    // fetch("/sponsors")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //   });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/michigan">
            <AdminPage />
          </Route>
		  <Route path="/zawody">
            <ZawodyPage />
          </Route>
		  <Route path="/test">
            <TestPage />
          </Route>
          <Route path="/about">
            <Navbar
             languageHandler={languageHandler}
			 isEnglish={isEnglish}
            />
            <AboutPage isEnglish={isEnglish} />
          </Route>
          <Route path="/team">
            <Navbar 
			languageHandler={languageHandler}
			isEnglish={isEnglish}
			/>
            <TeamPage isEnglish={isEnglish} />
          </Route>
          <Route path="/bolid">
            <Navbar 
			languageHandler={languageHandler}
			isEnglish={isEnglish}
			/>
            <BolidPage isEnglish={isEnglish} />
          </Route>
          <Route path="/partners">
            <Navbar 
			languageHandler={languageHandler}
			isEnglish={isEnglish}
			/>
            <PartnersPage isEnglish={isEnglish} />
          </Route>
          <Route path="/contact">
            <Navbar 
			languageHandler={languageHandler}
			isEnglish={isEnglish}
			/>
            <KontaktPage isEnglish={isEnglish} />
          </Route>
          <Route exact path="/">
            <Navbar
			languageHandler={languageHandler}
			isEnglish={isEnglish}
            />
            <MainPage
             isEnglish={isEnglish}
            />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
