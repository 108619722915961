import React, { useEffect } from "react";

import "../../styles/MainPage/BolidyPage.css";

import WUT5 from "../../images/Bolid/WUT5.jpg";
import BolidComponent from "../../components/BolidComponent";

const BolidyMain = (props: any) => {
  return (
    <section className="bolid-page">
      <BolidComponent
        srcPhoto={WUT5}
        isMain={true}
        moc={86}
        masa={219.5}
        przyspieszenie={4.0}
        maks={121}
		isEnglish={props.isEnglish}
      />
    </section>
  );
};

export default BolidyMain;
