//PR
import	Adam_Krawczyk	from "../../images/Team/PR/Adam_Krawczyk.jpg"
import	Kajetan_Bołtralik	from "../../images/Team/PR/Kajetan_Bołtralik.jpg"
import	Michał_Wiśniewski	from "../../images/Team/PR/Michał_Wiśniewski.jpg"
import	ZK_Magda_Sieńska	from "../../images/Team/PR/ZK_Magda_Sieńska.jpg"

//Silnik		
import	Igor_Syska	from "../../images/Team/Silnik/Igor_Syska.jpg"
import	Jakub_Dzikowski	from "../../images/Team/Silnik/Jakub_Dzikowski.jpg"
import	Jakub_Florczak	from "../../images/Team/Silnik/Jakub_Florczak.jpg"
import	Mateusz_Makarewicz	from "../../images/Team/Silnik/Mateusz_Makarewicz.jpg"
import	Michał_Milaniuk	from "../../images/Team/Silnik/Michał_Milaniuk.jpg"
import	Mikołaj_Klonowski	from "../../images/Team/Silnik/Mikołaj_Klonowski.jpg"
import	Przemysław_Karolak	from "../../images/Team/Silnik/Przemysław_Karolak.jpg"
import	ZK_Franciszek_Kajdzik	from "../../images/Team/Silnik/ZK_Franciszek_Kajdzik.jpg"
import	K_Kamil_Piszcz	from "../../images/Team/Silnik/K_Kamil_Piszcz.jpg"

//Nośna		
import	Aleksander_Abramowicz	from "../../images/Team/Struktura_Nosna/Aleksander_Abramowicz.jpg"
import	Filip_Polański	from "../../images/Team/Struktura_Nosna/Filip_Polański.jpg"
import	Igor_Stępień	from "../../images/Team/Struktura_Nosna/Igor_Stępień.jpg"
import	Jan_Czech	from "../../images/Team/Struktura_Nosna/Jan_Czech.jpg"
import	K_Kamil_Gardyjas	from "../../images/Team/Struktura_Nosna/K_Kamil_Gardyjas.jpg"
import	Krzysztof_Żarnowski	from "../../images/Team/Struktura_Nosna/Krzysztof_Żarnowski.jpg"
import	ZK_Grzegorz_Spruch	from "../../images/Team/Struktura_Nosna/ZK_Grzegorz_Spruch.jpg"

//Zarząd		
import	Kornelia_Kołodziejska	from "../../images/Team/Zarzad/Kornelia_Kołodziejska.jpg"
import	Marcin_Platek	from "../../images/Team/Zarzad/Marcin_Płatek.jpg"
import	Mateusz_Drabarek	from "../../images/Team/Zarzad/Mateusz_Drabarek.jpg"
import	Piotr_Polakowski	from "../../images/Team/Zarzad/Piotr_Polakowski.jpg"

//Zawias		
import	Adam_Chiliński	from "../../images/Team/Zawieszenie/Adam_Chiliński.jpg"
import	Andrzej_Graska	from "../../images/Team/Zawieszenie/Andrzej_Graska.jpg"
import	Grzegorz_Siedlecki	from "../../images/Team/Zawieszenie/Grzegorz_Siedlecki.jpg"
import	K_Tomasz_Kołakowski	from "../../images/Team/Zawieszenie/K_Tomasz_Kołakowski.jpg"
import	Maciej_Gleń	from "../../images/Team/Zawieszenie/Maciej_Gleń.jpg"
import	Maria_Sirko	from "../../images/Team/Zawieszenie/Maria_Sirko.jpg"
import	Mateusz_Krajewski	from "../../images/Team/Zawieszenie/Mateusz_Krajewski.jpg"
import	Miłosz_Mościcki	from "../../images/Team/Zawieszenie/Miłosz_Mościcki.jpg"
import	Norbert_Kołek	from "../../images/Team/Zawieszenie/Norbert_Kołek.jpg"
import	Szymon_Drzewiecki	from "../../images/Team/Zawieszenie/Szymon_Drzewiecki.jpg"
import	ZK_Ian_Gjellebol	from "../../images/Team/Zawieszenie/ZK_Ian_Gjellebol.jpg"

import	Kamil_Polowczyk	from "../../images/Team/Kamil_Polowczyk.jpg"

//Aero
import	Aleksandra_Sadowska	from "../../images/Team/Aero/Aleksandra_Sadowska.jpg"
import	Benedykt_Jeż	from "../../images/Team/Aero/Benedykt_Jeż.jpg"
import	Iga_Gamska	from "../../images/Team/Aero/Iga_Gamska.jpg"
import	Jakub_Pacocha	from "../../images/Team/Aero/Jakub_Pacocha.jpg"
import	K_Edwin_Podlewski	from "../../images/Team/Aero/K_Edwin_Podlewski.jpg"
import	Łukasz_Rybakowski	from "../../images/Team/Aero/Łukasz_Rybakowski.jpg"
import	Michał_Bartoszek	from "../../images/Team/Aero/Michał_Bartoszek.jpg"
import	Michał_Żyto	from "../../images/Team/Aero/Michał_Żyto.jpg"
import	Rafał_Szulejko	from "../../images/Team/Aero/Rafał_Szulejko.jpg"
import	Wiktor_Gutkowski	from "../../images/Team/Aero/Wiktor_Gutowski.jpg"
import	Wojciech_Baran	from "../../images/Team/Aero/Wojciech_Baran.jpg"
import	ZK_Tomasz_Polski	from "../../images/Team/Aero/ZK_Tomasz_Polski.jpg"
	
//Elektronika	
import	Bartek_Cieśla	from "../../images/Team/Elektronika/Bartek_Cieśla.jpg"
import	Błażej_Molas	from "../../images/Team/Elektronika/Błażej_Molas.jpg"
import	Filip_Jankowski	from "../../images/Team/Elektronika/Filip_Jankowski.jpg"
import	Jakub_Marcinowski	from "../../images/Team/Elektronika/Jakub_Marcinowski.jpg"
import	Jan_Sidor	from "../../images/Team/Elektronika/Jan_Sidor.jpg"
import	K_Grzegorz_Radzikowski	from "../../images/Team/Elektronika/K_Grzegorz_Radzikowski.jpg"
import	Kacper_Kowalski	from "../../images/Team/Elektronika/Kacper_Kowalski.jpg"
import	Marcin_Ciastoń	from "../../images/Team/Elektronika/Marcin_Ciastoń.jpg"
import	Michał_Kramkowski	from "../../images/Team/Elektronika/Michał_Kramowski.jpg"
import	Patrycja_Woźniak	from "../../images/Team/Elektronika/Patrycja_Woźniak.jpg"

//Fundraising		
import	Igor_Olczak	from "../../images/Team/Fundraising/Igor_Olczak.jpg"
import	Maciej_Chibowski	from "../../images/Team/Fundraising/Maciej_Chibowski.jpg"
import	Maja_Banasiak	from "../../images/Team/Fundraising/Maja_Banasiak.jpg"
import	Mateusz_Czucha	from "../../images/Team/Fundraising/Mateusz_Czucha.jpg"
import	Paweł_Polanowski	from "../../images/Team/Fundraising/Paweł_Polanowski.jpg"

//Logistyka		
import	Filip_Langiewicz	from "../../images/Team/Logistyka/Filip_Langiewicz.jpg"
import	Mateusz_Giermek	from "../../images/Team/Logistyka/Mateusz_Giermek.jpg"
import	Natalia_Dmytrak	from "../../images/Team/Logistyka/Natalia_Dmytrak.jpg"
import	Szymon_Modzelewski	from "../../images/Team/Logistyka/Szymon_Modzelewski.jpg"
import	Zofia_Wiecha	from "../../images/Team/Logistyka/Zofia_Wiecha.jpg"

import	brak	from "../../images/Team/default.svg"




interface Person {
    img?: any,
    name: string,
    description?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let zarzad: Person[] = [
    {img: Marcin_Platek, name:"Marcin Płatek", description:"Prezes"},
    {img: Kornelia_Kołodziejska, name:"Kornelia Kołodziejska", description:"Wiceprezes ds. organizacyjnych"},
    {img: Piotr_Polakowski, name:"Piotr Polakowski", description:"Wiceprezes ds. finansowych"},
    {img: Mateusz_Drabarek, name: "Mateusz Drabarek", description:"Wiceprezes ds. technicznych"}
]


let technicznyLider: Person = {img: Kamil_Polowczyk, name:"Kamil Polowczyk", description: "Lider działu technicznego"}

let aerodynamika: Dzial = {
    nameDzial: "DZIAŁ AERODYNAMIKI",
    leader: {img: K_Edwin_Podlewski, name: "Edwin Podlewski",description:"Koordynator działu Aerodynamiki"},
    persons: [
		{img: ZK_Tomasz_Polski, name: "Tomasz Polski", description: "Zastępca koordynatora"},
		{img: Michał_Żyto, name: "Michał Żyto"},
		{img: Aleksandra_Sadowska, name: "Aleksandra Sadowska"},
		{img: Benedykt_Jeż, name: "Benedykt Jeż"},
		{img: Iga_Gamska, name: "Iga Gamska"},
		{img: Jakub_Pacocha, name: "Jakub Pacocha"},
		{img: Łukasz_Rybakowski, name: "Łukasz Rybakowski"},
		{img: ZK_Magda_Sieńska, name: "Magda Sieńska"},
		{img: Michał_Bartoszek, name: "Michał Bartoszek"},
		{img: Rafał_Szulejko, name: "Rafał Szulejko"},
		{img: Wiktor_Gutkowski, name: "Wiktor Gutkowski"},
		{img: Wojciech_Baran, name: "Wojciech Baran"},

    ]
}

let nosna: Dzial = {
    nameDzial: "DZIAŁ STRUKTURY NOŚNEJ",
    leader: {img: ZK_Grzegorz_Spruch, name: "Grzegorz Spruch", description: "Koordynator działu Konstrukcji Nośnej"},
    persons: [
		{img: Aleksander_Abramowicz, name: "Aleksander Abramowicz"},
		{img: Filip_Polański, name: "Filip Polański"},
		{img: Igor_Stępień, name: "Igor Stępień"},
		{img: Jan_Czech, name: "Jan Czech"},
		//{img: Krzysztof_Żarnowski, name: "Krzysztof Żarnowski"},
    ]
}

let zawieszenie: Dzial = {
    nameDzial: "DZIAŁ ZAWIESZENIA",
    leader: {img: K_Tomasz_Kołakowski, name:"Tomasz Kołakowski", description:"Koordynator działu zawieszenia"},
    persons: [
		{img: ZK_Ian_Gjellebol, name: "Ian Gjellebol", description: "Zastępca koordynatora"},
		{img: Adam_Chiliński, name: "Adam Chiliński"},
		{img: Andrzej_Graska, name: "Andrzej Graska"},
		{img: Grzegorz_Siedlecki, name: "Grzegorz Siedlecki"},
		{img: Maciej_Gleń, name: "Maciej Gleń"},
		{img: Maria_Sirko, name: "Maria Sirko"},
		{img: Mateusz_Krajewski, name: "Mateusz Krajewski"},
		{img: Miłosz_Mościcki, name: "Miłosz Mościcki"},
		{img: Norbert_Kołek, name: "Norbert Kołek"},
		{img: Szymon_Drzewiecki, name: "Szymon Drzewiecki"},

    ]
}

let elektronika: Dzial = {
    nameDzial: "DZIAŁ ELEKTRONIKI",
    leader: {img: K_Grzegorz_Radzikowski, name: "Grzegorz Radzikowski", description:"Koordynator działu elektroniki"},
    persons: [
		{img: Mateusz_Drabarek, name: "Mateusz Drabarek", description: "Zastępca koordynatora"},
		//{img: Bartek_Cieśla, name: "Bartek Cieśla"},
		{img: Błażej_Molas, name: "Błażej Molas"},
		//{img: Filip_Jankowski, name: "Filip Jankowski"},
		{img: Jakub_Marcinowski, name: "Jakub Marcinowski"},
		{img: Jan_Sidor, name: "Jan Sidor"},
		{img: Kacper_Kowalski, name: "Kacper Kowalski"},
		{img: Marcin_Ciastoń, name: "Marcin Ciastoń"},
		{img: Michał_Kramkowski, name: "Michał Kramkowski"},
		{img: Patrycja_Woźniak, name: "Patrycja Woźniak"},

    ]
}

let silnik: Dzial = {
    nameDzial: "DZIAŁ SILNIKA",
    leader: {img: Michał_Milaniuk, name: "Michał Milaniuk", description:"Koordynator działu silnika"},
    persons: [
		{img: K_Kamil_Piszcz, name: "Kamil Piszcz", description: "Zastępca koordynatora"},
		{img: ZK_Franciszek_Kajdzik, name: "Franciszek Kajdzik"},
        {img: Igor_Syska, name: "Igor Syska"},
		{img: Jakub_Dzikowski, name: "Jakub Dzikowski"},
		{img: Jakub_Florczak, name: "Jakub Florczak"},
		{img: Mateusz_Makarewicz, name: "Mateusz Makarewicz"},
		{img: Mikołaj_Klonowski, name: "Mikołaj Klonowski"},
		{img: Przemysław_Karolak, name: "Przemysław Karolak"},

    ]
}

let operacyjnyLider: Person = {img: Kornelia_Kołodziejska, name: "Kornelia Kołodziejska", description:"Lider działu operacyjnego"}

let pr: Dzial = {
    nameDzial: "DZIAŁ PR",
    leader: {img: ZK_Magda_Sieńska, name: "Magda Sieńska", description: "Koordynator działu PR"},
    persons: [
		{img: Michał_Wiśniewski, name: "Michał Wiśniewski"},
		{img: Wojciech_Baran, name: "Wojciech Baran"},
		{img: brak, name: "Igor Kotłowski"},
    ]
}

let logistyka: Dzial = {
    nameDzial: "DZIAŁ LOGISTYKI",
    leader: {img: Mateusz_Giermek, name: "Mateusz Giermek", description: "Koordynator działu logistyki"},
    persons: [
		{img: Szymon_Modzelewski, name: "Szymon Modzelewski", description: "Zastępca koordynatora"},
		{img: brak, name: "Aleksandra Breś"},
    ]
}

let fundraising: Dzial = {
    nameDzial: "DZIAŁ FUNDRAISING",
    leader: {img: Kornelia_Kołodziejska, name: "Kornelia Kołodziejska", description: "Koordynator działu fundraisingu"},
    persons: [
		{img: Igor_Olczak, name: "Igor Olczak"},
		{img: Jakub_Pacocha, name: "Jakub Pacocha"},
		{img: Mateusz_Czucha, name: "Mateusz Czucha"},
    ]
}

export type {Dzial, Person};
export default {fundraising, logistyka, pr, silnik, elektronika, aerodynamika, nosna, zawieszenie, zarzad, technicznyLider, operacyjnyLider}
