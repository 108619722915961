import React, { useEffect, useState } from "react";
import team, {PersonDataAA} from "../ZawodyPage/Interfaces";
import events, {EventData} from "../ZawodyPage/Interfaces2";

const TestPage = () => {
	const [list, setList] = React.useState(team);
	
	/*function edit(id: any) {
		const newList = list.map((item) => {
			if (item.name === id) {
				const updatedItem = {
					...item,
					place: "aaaaa",
				};

				return updatedItem;
			}

			return item;
		});
		setList(newList);
	}*/
	
  return (
  <div>
	<p>jestem</p>
	<table>
	<tr>
		<th>Imie</th>
		<th>Dział</th>
		<th>Miejsce</th>
		<th>Czas</th>
		<th>Button</th>
	</tr>
	{team.map(member => (
		<tr>
			<td>{member.name}</td>
			<td>{member.role}</td>
			<td>{member.place="aaa"}</td>
			<td>{member.time.toLocaleString()}</td>
			<td><button type="button" >button</button></td>
		</tr>
	))}
	</table>
	</div>
	)
};

export default TestPage;
export {team};
