import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import { TimelineProps } from "react-gsap/dist/Timeline";
import BranchIcon from "../../icons/BranchIcon";
import FormulaIcon from "../../icons/FormulaIcon";
import PersonIcon from "../../icons/PersonIcon";
import CounterUp from "../../components/CounterUp";
import AboutPage from "../AboutPage/AboutPage";

const AboutMain = (props: any) => {
  return (
    <section className="about-front-page">
      <Controller>
        <Scene
          duration={500}
          pin={{ pushFollowers: true }}
          triggerHook={0.7}
          offset={100}
        >
          {(progress: any) => (
            <Timeline totalProgress={progress} paused>
              <Tween
                from={{ transform: "translateX(-1000px)" }}
                to={{ transform: "translateX(0px)" }}
              >
                <div className="about-headline">
                  <h1>KIM JESTEŚMY?</h1>
                </div>
              </Tween>
            </Timeline>
          )}
        </Scene>
      </Controller>

      <div className="info-kim">
        {/*<p className="kim-jestesmy-paragraph">
          Koło naukowe{" "}
          <strong>
            <i>WUT Racing</i>
          </strong>{" "}
          powstało w 2011 roku przy Wydziale Mechanicznym Energetyki i
          Lotnictwa, od tego czasu jednocząc pasjonatów motoryzacji, których
          celem jest skonstruowanie i zbudowanie bolidu wyścigowego
          przystosowanego do udziału w międzynarodowych zawodach Formula
          Student.
        </p>
        <AboutPage isEnglish={props.isEnglish} />*/}
		<p className="kim-jestesmy-paragraph">
		Zapnijcie pasy i przeżyjcie ekscytującą podróż z&nbsp;<strong>Kołem Naukowym <span style={{color: "#e31922"}}> WUT&nbsp;Racing</span>! </strong>  
		Nasza historia rozpoczęła się w&nbsp;2011 roku na Wydziale Mechanicznym Energetyki i&nbsp;Lotnictwa. 
		Od tego czasu, zjednoczeni pasją do motoryzacji, dążymy do wspólnego celu - skonstruowania bolidu wyścigowego, 
		który zmierzy się z&nbsp;wyzwaniami międzynarodowych zawodów <strong><i>Formula Student</i></strong>.
		<br/><br/>
		Jesteśmy wyjątkowym zespołem zrzeszającym utalentowanych studentów czołowych warszawskich uczelni, 
		których głównym celem jest osiągnięcie triumfu w prestiżowych zawodach FS. 
		Jak zamierzamy tego dokonać? Nasza droga do sukcesu to współpraca oparta na determinacji i&nbsp;pasji, 
		mająca na celu wspólne stworzenie konkurencyjnego bolidu.
		<br/><br/>
		Poprzez nasze zaangażowanie i&nbsp;wspólny wysiłek, kreujemy nie tylko maszynę z&nbsp;metalu i&nbsp;technologii, 
		ale także niepowtarzalną społeczność, w&nbsp;której każdy ma swój wkład w&nbsp;osiąganie wspólnego celu. 
		To dla nas więcej niż zwykły wyścig - to podróż w&nbsp;stronę spełniania marzeń i&nbsp;zdobywania uznania 
		na przestrzeni studenckiego motorsportu.
		</p>

        <div className="stats-div">
          <h2>WUT Racing w&nbsp;liczbach</h2>
          <div className="stats">
            <div className="ludzie">
              <PersonIcon />
              <CounterUp counter={80} />
              <h3>Studentów</h3>
            </div>
            <div>
              <BranchIcon />
              <CounterUp counter={8} />
              <h3>Działów</h3>
            </div>
            <div>
              <FormulaIcon />
              <CounterUp counter={5} />
              <h3>Bolidów</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMain;
