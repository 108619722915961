interface PersonDataAA {
  name: string;
  place?: string;
  role: string;
  time: Date;
}

const team: PersonDataAA[] = [
	{name: "Marcin Płatek", role: "Prezes", time: new Date()},
    {name: "Mateusz Drabarek", role: "V-ce prezes", time: new Date()},
    {name: "Michał Żyto", role: "Aero", place: "Kanada", time: new Date()},
	{name: "Michał Milaniuk", role: "Silnik", time: new Date()},
];


export type {PersonDataAA}; 
export default team;
