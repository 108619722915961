import React, { useEffect, useState } from "react";
import team, {PersonDataAA} from "../ZawodyPage/Interfaces";
import events, {EventData} from "../ZawodyPage/Interfaces2";
import "../../styles/ZawodyPage/ZawodyPage.css";

const ZawodyPage = () => {
  
  var now = new Date();
  const [count, setCount] = useState(now.getTime());
  useEffect(() => {
	const interval = setInterval(() => {
		setCount(count + 1000);
	},1000);
	
	return () => clearInterval(interval);}, [count]);
  
  //const interval = setInterval(function () {var now = new Date().getTime();}, 1000);
  
  
  function countdown(member: EventData) {

	// Find the distance between now and the count down date
	var distance = member.startTime.getTime() - count;

  // Time calculations for days, hours, minutes and seconds
	var hours = Math.floor((distance ) / (1000 * 60 * 60));
	var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);
	return hours+"h "+minutes+"m "+seconds+"s ";
}
  
  return (
  <div>
	<table className="table">
	<tr>
		<th>Imie</th>
		<th>Dział</th>
		<th>Miejsce</th>
		<th>Czas</th>
	</tr>
	{team.map(member => (
		<tr>
			<td>{member.name}</td>
			<td>{member.role}</td>
			<td>{member.place}</td>
			<td>{member.time.toLocaleString()}</td>
		</tr>
	))}
	</table>
	<br/>
	

	<table className="table">
	<tr>
		<th>Nazwa</th>
		<th>Miejsce</th>
		<th>Godzina rozpoczęcia</th>
		<th>Pozostało</th>
	</tr>
	{events.map(member => (
		<tr className={`${member.important}`}>
			<td>{member.name}</td>
			<td>{member.place}</td>
			<td>{member.startTime.toLocaleString().slice(11)}</td>
			<td>{countdown(member)}</td>
		</tr>
	))}
	</table>
	</div>
	)
};

export default ZawodyPage;
export {team};