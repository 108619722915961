interface EventData {
  name: string;
  place: string;
  startTime: Date;
  important: boolean;
}

const events: EventData[] = [
	{name: "Rozpoczęcie", place: "Kanapa", startTime: new Date(2024,6,11,17,30), important: false},
	{name: "Środek", place: "Sofa", startTime: new Date(), important: true},
	{name: "Koniec", place: "Pufa", startTime: new Date(), important: false},
];

export type {EventData}; 
export default events;